<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Attendance Register" slot="title" link="/helpContent/Monthly Attendance Report" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-3">
              <FormSelect label="Company" :items="companyList" item-name="Name" item-value="id" v-model="filtering.selectedCompanyId" rules="required" />
          </div>
          <div class="col-sm-2">
              <Year label="Year" v-model="filtering.selectedYear" rules="required" />
          </div>
          <div class="col-sm-2">
              <InputMonth label="Month" v-model="filtering.selectedMonth" rules="required" />
          </div>
      </FormRow>
    </ValidationObserver>
    <TableButton @click="() => exportData()">
      <img :src="require(`Assets/images/Excel_Icon.png`)"  />
      Export
    </TableButton>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import Year from "../../../components/Year";
  import loadingMixin from "Mixins/loadingMixin";
  import { getAllCompanyList, getAllStaffAttendanceForMonth } from "../api";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from "vee-validate";
  export default {
    name: "List",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      PageTitle,
      Year,
      ValidationObserver,
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    data() {
      return {
        filtering: {
          selectedCompanyId: null,
          selectedYear: new Date().getFullYear(),
          selectedMonth: new Date().getMonth() + 1,
        },
        companyList: []
      };
    },
    created() {
      this.getAllList();
    },
    methods: {
      async getAllList() {
        getAllCompanyList().then((res) => {
          this.companyList = res.data;
        });
      },
      exportData() {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            setTimeout(() => {
              const data = {
                companyId : this.filtering.selectedCompanyId,
                month: this.filtering.selectedMonth,
                year: this.filtering.selectedYear
              };
              getAllStaffAttendanceForMonth(data)
                .then(this.handleExportResponse)
                .catch(this.handleError);
            }, 50);
          }
        });
      },
      handleExportResponse(response) {
          window.open(response.data.url,'_blank');
      },
    },
  };
</script>

<style lang="scss" module>
</style>